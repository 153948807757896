import type { TeaserListItem } from '~/@types/cms';

// https://pinia.vuejs.org/core-concepts/#setup-stores
export const useMicroDataStore = defineStore('woom-store-micro-data', () => {
    const config = useRuntimeConfig();
    const router = useRouter();
    const { isUS, country } = useLocales();

    const baseUrl = config.public.BASE_URL;

    const phoneByCountry: Record<string | 'fallback', string> = {
        at: '+43800404332',
        de: '+498004043000',
        ch: '+41800550349',
        us: '+1855-966-6872',
        fallback: '+4313585803',
    };

    router.beforeEach(() => {
        contentType.value = undefined;
        blogHeaderData.value = undefined;
        blogPostsData.value = [];
    });

    const regionalValue = <USType, EUType>(usValue: USType, euValue: EUType) => (isUS.value ? usValue : euValue);

    /**
     * Old model: https://gitlab.woombikes.com/website/woom-lib-model/-/blob/master/src/model/Taxonomies/ContentType.ts
     *
     * New model: https://gitlab.woombikes.com/website/woom-webhooks-kontentai/-/blob/main/src/woom-lib-model/src/model/Taxonomies/ContentType.ts
     */
    type ContentTypes = 'blog_listing' | 'blog_post' | 'press_listing' | 'press_post';
    const contentType = ref<ContentTypes>();
    const setContentType = (type: string | undefined) => {
        contentType.value = type as ContentTypes | undefined;
    };

    type BlogHeader = { name: string; description?: string; datePublished?: string };
    const blogHeaderData = ref<BlogHeader>();
    const setBlogHeader = (data: BlogHeader) => {
        blogHeaderData.value = data;
    };

    const blogPostsData = ref<TeaserListItem[]>([]);
    const addBlogPostsData = (data: TeaserListItem[]) => {
        blogPostsData.value = blogPostsData.value.concat(data);
    };

    useJsonld(() => {
        if (contentType.value !== 'blog_listing' || !blogHeaderData.value || !blogPostsData.value.length) return null;
        return {
            '@context': 'https://schema.org',
            '@type': 'Blog',
            ...blogHeaderData.value,
            blogPost: blogPostsData.value.map((item) => {
                let image;
                if (item.hero && typeof item.hero !== 'string' && isAssetKontentAi(item.hero)) {
                    image = {
                        '@type': 'ImageObject',
                        '@id': item.hero?.data?.src,
                        url: item.hero?.data?.src,
                        width: item.hero?.data.width,
                        height: item.hero?.data.height,
                    };
                }
                if (item.hero && typeof item.hero !== 'string' && isAssetBynder(item.hero)) {
                    image = {
                        '@type': 'URL',
                        '@id': `${item.hero?.data.baseUrl}${item.hero.data.original || ''}`,
                        url: `${item.hero?.data.baseUrl}${item.hero.data.original || ''}`,
                    };
                }
                return {
                    '@type': 'BlogPosting',
                    headline: item.title,
                    description: item.subtitle,
                    datePublished: item.date ? item.date.split('T')[0] : undefined,
                    url: `${baseUrl}${item.url}`,
                    author: item.author?.name
                        ? {
                              '@type': 'Person',
                              name: item.author.name,
                          }
                        : undefined,
                    image,
                };
            }),
        };
    });

    const socialLinks = ref<string[]>();
    const setSocialLinks = (data: string[]) => {
        socialLinks.value = data;
    };
    useJsonld(() => ({
        '@context': 'https://schema.org',
        '@type': 'Organization',
        url: baseUrl,
        sameAs: socialLinks.value,
        logo: `${baseUrl}/woom-logo.svg`,
        name: regionalValue('woombikes USA, LLC', 'woom GmbH'),
        // TODO: add with https://onewoom.atlassian.net/browse/ECOMM-2504
        // description: 'Text from CMS',
        email: regionalValue('usa@woom.com', 'woom@woom.com'),
        telephone: phoneByCountry[country.value] || phoneByCountry['fallback'],
        address: {
            '@type': 'PostalAddress',
            streetAddress: regionalValue('8301 Springdale Rd, Ste. 800', 'Muthgasse 109 A'),
            addressLocality: regionalValue('Austin', 'Vienna'),
            addressRegion: regionalValue('TX', undefined),
            postalCode: regionalValue('78724', '1100'),
            addressCountry: regionalValue('United States of America', 'Austria'),
        },
        vatID: regionalValue(undefined, 'ATU67805716'),
    }));

    return { setContentType, setBlogHeader, addBlogPostsData, setSocialLinks };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useMicroDataStore, import.meta.hot));
}
